import { collection, doc, getDoc, getDocs, query, setDoc } from "firebase/firestore";
import { auth, db, functions } from "./config/config";
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, GoogleAuthProvider, signInWithPopup } from "firebase/auth"
import $ from "jquery";
import axios from "axios";
import { httpsCallable } from "firebase/functions";
window.$ = window.jQuery = $;
var stripe = Stripe("pk_live_51LT4TdE9LPhG2ozytMFohz9PTNXDSqAqBqLZAKIHxF3zlW3ZlfHdhnuYlGPwN7QVfW93IRuiEKRuoslD6pHxlNkE0071jolTGG");

var createAccountEmail = document.querySelector("[data-id='createAccountEmail']");
var createAccountFullName = document.querySelector("[data-id='createAccountFullName']");
var createAccountPassword = document.querySelector("[data-id='createAccountPassword']");
var createAccountDiv = document.querySelector("[data-id='createAccountDiv']");
var createAccountStartBtn = document.querySelector("[data-id='createAccountStartBtn']");
var googleSSOBtn = document.querySelector("[data-id='googleSSOBtn']");
var paymentModalCloseBtn = document.querySelector("[data-id='paymentModalCloseBtn']");
var setupModelScreen = document.querySelector("[data-id='setupModelScreen']");
var creatorsName = document.querySelector("[data-id='creatorsName']");
var creatorGender = document.querySelector("[data-id='creatorGender']");
var continueToDownloadScreen = document.querySelector("[data-id='continueToDownloadScreen']");
var downloadScreen = document.querySelector("[data-id='downloadScreen']");
var sendDownloadLinkBtn = document.querySelector("[data-id='sendDownloadLinkBtn']");
var pricingScreen = document.querySelector("[data-id='pricingScreen']");
var startTrialBtn = document.querySelector("[data-id='startTrialBtn']");

var fullName;
var monogram;
var chatID;
var customerID;

axios.defaults.headers.post['Content-Type'] = 'application/json';

onAuthStateChanged(auth, async (user) => {
  if (user) {
    console.log(user)
    createAccountDiv.style.display = "none";
    await getDoc(doc(db, "users", auth.currentUser.uid)).then(async (usersDoc) => {
      if (usersDoc.exists()) {
        let onboardingStage = usersDoc.data()?.onboardingMap?.setupStage;
        let incompleteUser = usersDoc.data()?.onboardingMap?.incompleteUser;

        if (incompleteUser === true || incompleteUser === undefined) {
          if (usersDoc.data()?.modelsMap !== undefined) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const redirectStatus = urlParams.get("redirect_status");
            console.log(redirectStatus)
            if (redirectStatus === "succeeded") {
              await fetch("https://us-central1-opafai.cloudfunctions.net/api/createStripeSubscription", {
                method: "POST",
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  userID: auth.currentUser.uid
                })
              }).then((r) => r.json()).then(async (data) => {
                console.log(data);
              })
              await setDoc(doc(db, "users", auth.currentUser.uid), {
                onboardingMap: {
                  setupStage: "finished",
                  incompleteUser: false
                },
                emailList: {
                  abandonedCheckout: false
                }
              }, {merge: true})
              const addToTrialList = httpsCallable(functions, "addToSendgrid");
              addToTrialList({
                email: auth.currentUser.email,
                type: "newTrial"
              })
              const removeFromAbandonedList = httpsCallable(functions, "addToSendgrid");
              removeFromAbandonedList({
                email: auth.currentUser.email,
                type: "removeFromAbandonedList"
              })
              downloadScreen.style.display = "flex";
            } else {
              pricingScreen.style.display = "flex";
            }
          } else {
            setupModelScreen.style.display = "flex";
          }
        } else {
          downloadScreen.style.display = "flex";          
        }
      }
    })
  } else {
    console.log("Not signed in.")
    createAccountDiv.style.display = "flex";
  }
})

createAccountStartBtn.addEventListener("click", async function () {
  const fullName = createAccountFullName.value;
  const email = createAccountEmail.value;
  const password = createAccountPassword.value;
  createAccountStartBtn.innerHTML = `<i class="fa fa-spinner fa-spin"></i>`;
  if (createAccountEmail.value !== "" && createAccountFullName.value !== "" && createAccountPassword.value !== "") {
    await createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      console.log(userCredential)
      fetch("https://us-central1-opafai.cloudfunctions.net/api/createStripeCustomer", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          name: fullName,
          firebaseUID: auth.currentUser.uid
        })
      }).then((r) => r.json()).then(async (data) => {
        console.log(data);
        customerID = data.customerId; 
        const sendWelcomeEmail = httpsCallable(functions, "addToSendgrid");
        sendWelcomeEmail({
          email: auth.currentUser.email,
          first_name: fullName.split(" ")[0],
          type: "newUser"
        })
        await setDoc(doc(db, "users", auth.currentUser.uid), {
          fullName: fullName,
          email: email,
          onboardingMap: {
            setupStage: "subscriptionScreen",
            incompleteUser: true
          },
          stripeDetailsMap: {
            stripeCustomerID: customerID
          }
        })
        createAccountStartBtn.innerText = `Continue`;
        setupModelScreen.style.display = "flex";
      })
    });
  }
})

googleSSOBtn.addEventListener("click", async function () {
  const googleProvider = new GoogleAuthProvider();
  await signInWithPopup(auth, googleProvider).then((userCredential) => {
    console.log(userCredential)
    const fullName = auth.currentUser.displayName;
    const email = auth.currentUser.email;
    fetch("https://us-central1-opafai.cloudfunctions.net/api/createStripeCustomer", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        name: fullName,
        firebaseUID: auth.currentUser.uid
      })
    }).then((r) => r.json()).then(async (data) => {
      console.log(data);
      customerID = data.customerId; 
      const sendWelcomeEmail = httpsCallable(functions, "addToSendgrid");
      sendWelcomeEmail({
        email: auth.currentUser.email,
        first_name: fullName.split(" ")[0],
        type: "newUser"
      })
      await setDoc(doc(db, "users", auth.currentUser.uid), {
        fullName: fullName,
        email: email,
        onboardingMap: {
          setupStage: "subscriptionScreen",
          incompleteUser: true
        },
        stripeDetailsMap: {
          stripeCustomerID: customerID
        },
        emailList: {
          abandonedCheckout: true
        }
      })
      createAccountStartBtn.innerText = `Continue`;
      setupModelScreen.style.display = "flex";
    }, {merge: true})
  })
})

continueToDownloadScreen.addEventListener("click", async (e) => {
  continueToDownloadScreen.innerHTML = `<i class="fa fa-spinner fa-spin"></i>`;
  await setDoc(doc(db, "users", auth.currentUser.uid), {
    modelsMap: {
      [1]: {
        modelsName: creatorsName.value,
        modelsGender: creatorGender.value,
        modelMetadata: {
          avatarStorageURL: "public/logos/botly_logo_3d.jpg",
          dateAdded: new Date(),
          addedBy: auth.currentUser.uid,
          initialModel: true,
        },
        messageHistory: {
          "0000": {
            "0000": {
              messageContent: "placeholder"
            }
          }
        },
        selected: true,
        unfinishedModel: false,
      },
      modelCount: 1,
    }
  }, {merge: true}).then(async () => {
    setupModelScreen.style.display = "none";
    pricingScreen.style.display = "flex";
  })
})

startTrialBtn.addEventListener("click", async () => {
  startTrialBtn.innerHTML = `<i class="fa fa-spinner fa-spin"></i>`;
  await getDoc(doc(db, "users", auth.currentUser.uid)).then(async (doc) => {
    if (doc.exists()) {
      let stripeCustomerID = doc.data().stripeDetailsMap.stripeCustomerID;
      await fetch("https://us-central1-opafai.cloudfunctions.net/api/paymentDetailsCapture", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subscriptionType: "monthly",
          quantity: "1",
          customerId: stripeCustomerID,
          userID: auth.currentUser.uid
        })
      }).then((r) => r.json()).then(async (data) => {
        console.log(data);
        let clientSecret = data.clientSecret;
        const options = {
          clientSecret: clientSecret,
          appearance: {
            theme: "flat",
            variables: {
              colorPrimary: '#2F41E3',
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: "600",
              borderRadius: "8px",
              colorText: "#333333",
              colorDanger: "#E92525",
              fontSizeBase: "16px",
              colorTextPlaceholder: "#ADADAD",
              colorSuccess: "#00CF91",
              fontLineHeight: "20px",
              spacingUnit: "4px"
            },
            rules: {
              ".Input:focus": {
                border: "1px solid #2F41E3",
                boxShadow: "none",
              },
              ".Input": {
                backgroundColor: "#FFFFFF",
                border: "1px solid #CECECE",
                boxShadow: "none",
                borderRadius: "8px",
                padding: "12px 16px",
              },
              ".Input::placeholder": {
                color: "#ADADAD"
              }
            }
          }
        }
        // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 5
        const elements = stripe.elements(options);
        const addressElement = elements.create('address', { 
          mode: 'billing',
          defaultValues: {
            name: doc.data().fullName,
          }
        });
        const paymentElement = elements.create('payment');
        const expressCheckoutElement = elements.create('expressCheckout')
        expressCheckoutElement.mount('#express-checkout-element');
        addressElement.mount('#address-element');
        paymentElement.mount('#payment-element');
  
        document.getElementById('paymentModal').style.display = "flex";
        startTrialBtn.innerHTML = `Continue`;

        const form = document.getElementById('payment-form');
        const submitBtn = document.getElementById('submit');

        const handleError = (error) => {
          const messageContainer = document.querySelector('#error-message');
          messageContainer.textContent = error.message;
          submitBtn.disabled = false;
        }

        form.addEventListener('submit', async (event) => {
          // We don't want to let default form submission happen here,
          // which would refresh the page.
          event.preventDefault();

          // Prevent multiple form submissions
          if (submitBtn.disabled) {
            return;
          }

          // Disable form submission while loading
          submitBtn.disabled = true;

          // Trigger form validation and wallet collection
          const { error: submitError } = await elements.submit();

          if (submitError) {
            handleError(submitError);
            return;
          }

          // Confirm the SetupIntent using the details collected by the Payment Element
          const {error} = await stripe.confirmSetup({
            elements,
            clientSecret,
            confirmParams: {
              return_url: `${window.location.href}`,
            },
          });

          if (error) {
            // This point is only reached if there's an immediate error when
            // confirming the setup. Show the error to your customer (for example, payment details incomplete)
            handleError(error);
          }
        });
      })
    }
  })
})

sendDownloadLinkBtn.addEventListener("click", async () => {
  sendDownloadLinkBtn.innerHTML = `<i class="fa fa-spinner fa-spin"></i>`;
  const sendChromeDownloadLink = httpsCallable(functions, "sendChromeDownloadLink");
  await sendChromeDownloadLink({
    uid: auth.currentUser.uid,
  }).then((data) => {
    sendDownloadLinkBtn.innerHTML = `Email sent`;
    sendDownloadLinkBtn.style.background = "#25E9AE";
    sendDownloadLinkBtn.style.border = "1px solid #25E9AE";
    sendDownloadLinkBtn.style.color = "#FFFFFF";
    setTimeout(() => {
      sendDownloadLinkBtn.style.background = "#FFFFFF";
      sendDownloadLinkBtn.style.border = "1px solid #2F41E3";
      sendDownloadLinkBtn.style.color = "#2F41E3";
      sendDownloadLinkBtn.innerHTML = `Send email`;
    }, 2000)
  })

})

paymentModalCloseBtn.addEventListener("click", () => {
  document.getElementById('paymentModal').style.display = "none";
})

window.signOut = () => {
  signOut(auth);
}

window.createAssessment = async function (token) {
  await fetch(`https://recaptchaenterprise.googleapis.com/v1/projects/opafai/assessments?key=${import.meta.env.VITE_recaptchaKey}`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "event": {
        "token": token,
        "expectedAction": "SIGNUP",
        "siteKey": "6LdWQOopAAAAAFBI34pftUiyRsL_jYcz3ivPQC20",
      }
    })
  }).then(res => res.json()).then((res) => {
    console.log(res)
    if (res.tokenProperties.valid === true) {
      createAccountStartBtn.disabled = false;
    }
  })
}